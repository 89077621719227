body, html {
    height: 100%; /* Ensure the html and body are full height */
    margin: 0; /* Reset default margin */
    text-align: center; /* Center aligns all content */
    font-family: Arial, sans-serif; /* Sets a default font */
    background-color: #D3A17E; /* Background color for the whole page */
}

/* Center content vertically */
.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

/* ... other styles ... */

.project-link {
    display: block; /* Makes the <p> a block-level element, causing a line break before and after */
    text-align: center; /* Centers the text */
    margin: 20px 0; /* Adds space above and below the paragraph */
}

/* ... other styles ... */

header h1 {
    font-weight: bold; /* Makes the heading bold */
    color: #5645A1; /* Color for the "FREETAIL" heading */
    font-size: 4em; /* Increase the size of the heading */
    margin: 0; /* Reset default margin */
}

header p {
    color: #29261B; /* Color for the text below the heading */
    margin: 20px 0; /* Add some space above and below the paragraph */
}

footer {
    background-color: #D3A17E; /* Same as page background */
    color: #000000; /* Black text color */
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px; /* Adds some space inside the footer */
    box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

footer p {
    margin: 5px 0; /* Spacing between footer lines */
}

